import PageAbout from '../components/page-about'
import Seo from '../components/seo'

const About = () => (
  <>
    <Seo title="About" />
    <PageAbout />
  </>
)

export default About
