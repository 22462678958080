import Callout from '../callout'
import Container from '../container'
import Icon from '../icon'
import Page, { Header } from '../page'
import Typography from '../typography'
import {
  pageAbout,
  pageAboutFullWidthSection,
  pageAboutSection,
} from './page-about.module.scss'

const PageAbout = () => (
  <>
    <Page className={pageAbout}>
      <Container>
        <Header
          title="What makes our custom software shop tick?"
          subtitle={
            <>
              100% in-house development, testing, and design, along with almost{' '}
              <strong>a decade</strong> of experience building for the web and
              mobile.
            </>
          }
        />
      </Container>
      <Container as="section" className={pageAboutSection}>
        <div>
          <Typography variant="h4">
            <strong>Who</strong> we are
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterTop>
            We&apos;re a team of developers, designers, product managers and
            quality assurance engineers with a passion for solving the complex
            business challenges faced by startups and enterprise-level clients
            with custom software applications.
          </Typography>
        </div>
        <Icon name="team-up" />
      </Container>

      <section className={pageAboutFullWidthSection}>
        <Container className={pageAboutSection}>
          <Typography color="inherit" variant="h3">
            We Build <strong>Complete</strong> Web and Mobile Solutions.
          </Typography>
          <div>
            <Typography color="inherit" variant="h4">
              <strong>What</strong> we do
            </Typography>
            <Typography color="inherit" variant="body1" gutterTop>
              We take a “full-stack” approach to web and mobile application
              development. Everything is planned, designed, architected, coded,
              and tested completely in-house. We’re sticklers for detail and
              love a good challenge. We build applications the right way: with
              data and business logic separated from the front-end, user-facing
              components. This is enterprise-grade, industrial-strength
              application development, and we have the processes and practices
              to do it.
            </Typography>
          </div>
        </Container>
      </section>

      <Container as="section" className={pageAboutSection}>
        <div>
          <Typography variant="h4">
            <strong>Why</strong> choose us
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterTop>
            Choosing a team that’s easy to work with and who understands you and
            your business is important in any relationship. When you’re talking
            custom software, it’s absolutely critical. You need a partner that
            can anticipate your needs, ask the right questions and always be
            thinking several steps ahead. We develop with every aspect of your
            application in mind and we pride ourselves at being very good at
            thinking of something you may have missed.
          </Typography>
        </div>
        <Icon name="result" />
      </Container>
    </Page>
    <Callout />
  </>
)

export default PageAbout
